import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./blog-post.module.scss";


const BlogPostTemplate = ({data}) => {
    const post = data.article
    return (
        <div>
            <div className="small-container mt-5">
                <h1>{post.frontmatter.title}</h1>
                <div className="mb-4 d-flex justify-content-between text-black-50 fw-light fst-italic">
                    <span>Publié le {post.frontmatter.date}</span>
                    <span>{post.timeToRead} min</span>
                </div>
                <div>
                    <span></span>
                </div>
                {post.frontmatter.image &&
                    <GatsbyImage alt={post.frontmatter.image.id} image={getImage(post.frontmatter.image.childImageSharp)} className="figure-img img-fluid rounded"/>
                }
                <div dangerouslySetInnerHTML={{__html: post.html}}/>
                <div className={`row ${styles.pagination}`}>
                    <div className="col-md-6">
                        {data.prev && 
                        <Link to={`/blog${data.prev.fields.slug}`} className={styles.prev}>
                            <span>Précédent</span>
                            <h6>{data.prev.frontmatter.title}</h6>
                        </Link>
                        }
                    </div>
                    <div className="col-md-6">
                        {data.next &&
                        <Link to={`/blog${data.next.fields.slug}`} className={styles.next}>
                            <span>Suivant</span>
                            <h6>{data.next.frontmatter.title}</h6>
                        </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export const query = graphql`
    query($slug: String!, $prev: String, $next: String) {
        article: markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                image {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.8
                            placeholder: BLURRED
                        )
                    }
                }
                date(formatString: "D MMMM YYYY", locale: "FR-fr")
            }
            timeToRead
        }
        prev: markdownRemark(id: { eq: $prev } ) {
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
        next: markdownRemark(id: { eq: $next } ) {
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
    }
`

export default BlogPostTemplate;